import { Button, ButtonProps } from "@mantine/core";

interface TestBaseButtonProps extends ButtonProps {
    children: string
    onClick: (e: any) => void
}

const BaseButton = (params: TestBaseButtonProps) => {

    const { onClick, ...paramEtc } = params

    return <Button
        style={{
            display: 'flex',
            width: '143px',
            height: '40px',
            padding: '8px 15px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
            flexShrink: "0",
            borderRadius: '3px',
            border: '1px solid #E7E9F2',
            background: '#F5F6FB',
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)'
        }}
        onClick={onClick} {...paramEtc} />
}



export default BaseButton