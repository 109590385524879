import styled from "@emotion/styled";
import { createContext, } from "react";

import Users from "./Users";
export const UsersCTX = createContext<any>(null);

function Index() {
  const contextValue = { test: "false" };

  return (
    <>
      <UsersCTX.Provider value={contextValue}>
        <Users />
      </UsersCTX.Provider>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: auto;

  background-color: ${({ theme }) => theme.backgroundColor};
  padding: 20px 23px;
  display: flex;
  gap: 17px;
`;

const ContentSection = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ContentTitle = styled.h1`
  color: #eef0f4;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 700;
  word-wrap: break-word;
  margin: 30px 0;
`;

const MainContent = styled.div`
  flex: 1;
`;

export default Index;
