import { DealersGet200ResponseRowsInner } from "@sizlcorp/mbk-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { MasterInstance } from "instance/axios";

const fetchDealersGet = (params: any) => {
    return MasterInstance.dealersFindPost(params);
}

export const useDealersQuery = (params: any) => {
    return useQuery(
        ["dealers-multi-get-name"],
        () => fetchDealersGet(params),
        {
            select: (data: AxiosResponse<DealersGet200ResponseRowsInner[], any>) => {
                return data.data.map((item) => ({
                    value: String(item.dealerHqCode),
                    label: String(item.dealerHq),
                }))
            }
        }
    );
};