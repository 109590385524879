import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiSitesFindPostRequest, MasterApiSitesGetRequest, MasterApiSitesPostRequest, MasterApiSitesSiteIdDeleteRequest, MasterApiSitesSiteIdGetRequest, MasterApiSitesSiteIdPutRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { AxiosRequestConfig } from "axios";
import { MasterInstance } from "instance/axios";


export const site = createQueryKeys("site", {
    all: null,
    get: (params: MasterApiSitesGetRequest, options?: AxiosRequestConfig) => {
        const query = { ...params };

        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => MasterInstance.sitesGet(query, options)
        }
    },
    find: (params: MasterApiSitesFindPostRequest) => {
        return {
            queryKey: [params, "site_find"],
            queryFn: () => MasterInstance.sitesFindPost(params)
        }
    },
    detail: (params: MasterApiSitesSiteIdGetRequest) => {
        return {
            queryKey: [params.siteId, params],
            queryFn: () => MasterInstance.sitesSiteIdGet(params)
        }
    }
});

export const mutateSite = createMutationKeys("site", {
    create: (params: MasterApiSitesPostRequest) => ({
        mutationKey: [params.sitesGetRequest],
        mutationFn: () => MasterInstance.sitesPost(params)
    }),
    update: (params: MasterApiSitesSiteIdPutRequest) => ({
        mutationKey: [params.siteId, params.sitesGetRequest],
        mutationFn: () => MasterInstance.sitesSiteIdPut(params)
    }),
    delete: (params: MasterApiSitesSiteIdDeleteRequest) => ({
        mutationKey: [params.siteId],
        mutationFn: () => MasterInstance.sitesSiteIdDelete(params)
    })
});