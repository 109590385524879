import { useQuery } from '@tanstack/react-query';
import { ticketReports } from 'api/ticketReport/useTicketReportQuery';
import { BarElement, CategoryScale, Chart as ChartJS, ChartOptions, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { convertArrayToNumbers, extractMaxValue } from 'utils/convert';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const MonthChart = ({ showOnlyMine }: { showOnlyMine: boolean }) => {

  const { data } = useQuery(ticketReports.postMonthStatus({
    assignedTickets: showOnlyMine
  }));

  const weekLabels = data?.data.map((item) => item.week);
  const weekCounts = data?.data.map((item) => item.count) ?? [];
  const weekMaxData = extractMaxValue(convertArrayToNumbers(weekCounts)) * 1.2;

  const chartData = {
    labels: weekLabels,
    datasets: [
      {
        label: '',
        data: weekCounts,
        backgroundColor: '#008DFC',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        // color: (context) => {
        //   const value = context.dataset.data[context.dataIndex];
        //   return value === 0 ? 'black' : 'red';
        // },
        font: {
          weight: 'bold',
          size: 16,
        },
        formatter: (value) => {
          return value;
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        beginAtZero: true,
        max: weekMaxData, // y축 최대값 설정
      },
    },

  } as ChartOptions<"bar">;

  return <Bar data={chartData} options={options} style={{ position: "relative" }} height={100} />;
};

export default MonthChart;
