import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import { TicketApiTicketsGetRequest, TicketApiTicketsPostRequest, TicketApiTicketsRemoveDeleteRequest, TicketApiTicketsTicketIdAcceptPutRequest, TicketApiTicketsTicketIdAwaitingReceiptPutRequest, TicketApiTicketsTicketIdAwaitingStagePutRequest, TicketApiTicketsTicketIdCancelClosedAutoPutRequest, TicketApiTicketsTicketIdCancelFinishAutoPutRequest, TicketApiTicketsTicketIdClosedPutRequest, TicketApiTicketsTicketIdConfirmDonePutRequest, TicketApiTicketsTicketIdDeleteRequest, TicketApiTicketsTicketIdDeliverPutRequest, TicketApiTicketsTicketIdFinishAutoPutRequest, TicketApiTicketsTicketIdFinishProcessPutRequest, TicketApiTicketsTicketIdForceDeliverPutRequest, TicketApiTicketsTicketIdForceFinishPutRequest, TicketApiTicketsTicketIdGetRequest, TicketApiTicketsTicketIdParkingLocationHistoryGetRequest, TicketApiTicketsTicketIdPendAutoPutRequest, TicketApiTicketsTicketIdPutRequest, TicketApiTicketsTicketIdResumeAutoPutRequest, TicketApiTicketsTicketIdStartAutoPutRequest, TicketApiTicketsTicketIdStartProcessPutRequest, TicketApiTicketsTicketIdUpdateParkingLocationPutRequest, TicketUserViewApiTicketUserViewGetRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { TicketInstance, TicketUserViewInstance } from "instance/axios";

export const tickets = createQueryKeys("tickets", {
    all: null,
    get: (params: TicketApiTicketsGetRequest) => {
        const query = { ...params };

        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => TicketInstance.ticketsGet(query)
        }
    },
    getView: (params: TicketUserViewApiTicketUserViewGetRequest) => {
        const query = { ...params };

        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => TicketUserViewInstance.ticketUserViewGet(query)
        }
    },
    detail: (params: TicketApiTicketsTicketIdGetRequest) => {
        return {
            queryKey: [params.ticketId, params],
            queryFn: () => TicketInstance.ticketsTicketIdGet(params)
        }
    },
});

export const mutateTickets = createMutationKeys("tickets", {
    create: (params: TicketApiTicketsPostRequest) => {
        return {
            mutationKey: [params.ticketsGetRequest],
            mutationFn: () => TicketInstance.ticketsPost(params)
        }
    },
    update: (params: TicketApiTicketsTicketIdPutRequest) => {
        return {
            mutationKey: [params.ticketId, params.ticketsTicketIdDeleteRequest],
            mutationFn: () => TicketInstance.ticketsTicketIdPut(params)
        }
    },
    delete: (params: TicketApiTicketsTicketIdDeleteRequest) => {
        return {
            mutationKey: [params.ticketId],
            mutationFn: () => TicketInstance.ticketsTicketIdDelete(params)
        }
    },
    // 일곽 삭제
    removeDelete: (params: TicketApiTicketsRemoveDeleteRequest) => {
        return {
            mutationKey: [params.ticketsRemoveDeleteRequest],
            mutationFn: () => TicketInstance.ticketsRemoveDelete(params)
        }
    },
    // 정비대기 확인
    acceptPut: (params: TicketApiTicketsTicketIdAcceptPutRequest) => {
        return {
            mutationKey: [params.ticketId],
            mutationFn: () => TicketInstance.ticketsTicketIdAcceptPut(params)
        }
    },
    // 마감 취소
    cancelClose: (params: TicketApiTicketsTicketIdCancelClosedAutoPutRequest) => {
        return {
            mutationKey: [params.ticketId, params.ticketsTicketIdCancelClosedAutoPutRequest],
            mutationFn: () => TicketInstance.ticketsTicketIdCancelClosedAutoPut(params)
        }
    },
    // 정비 종료 취소
    cancelFinishAuto: (params: TicketApiTicketsTicketIdCancelFinishAutoPutRequest) => {
        return {
            mutationKey: [params.ticketId, params.ticketsTicketIdCancelFinishAutoPutRequest],
            mutationFn: () => TicketInstance.ticketsTicketIdCancelFinishAutoPut(params)
        }
    },
    // 접수건 마감
    close: (params: TicketApiTicketsTicketIdClosedPutRequest) => {
        return {
            mutationKey: [params.ticketId, params.ticketsTicketIdClosedPutRequest],
            mutationFn: () => TicketInstance.ticketsTicketIdClosedPut(params)
        }
    },
    // 정비 종료 확인
    confirmDone: (params: TicketApiTicketsTicketIdConfirmDonePutRequest) => {
        return {
            mutationKey: [params.ticketId],
            mutationFn: () => TicketInstance.ticketsTicketIdConfirmDonePut(params)
        }
    },
    // 출고
    deliver: (params: TicketApiTicketsTicketIdDeliverPutRequest) => {
        return {
            mutationKey: [params.ticketId, params.ticketsTicketIdDeliverPutRequest],
            mutationFn: () => TicketInstance.ticketsTicketIdDeliverPut(params)
        }
    },
    // 접수건 정비 종료 또는 작업 단계 완료
    finishAuto: (params: TicketApiTicketsTicketIdFinishAutoPutRequest) => {
        return {
            mutationKey: [params.ticketId],
            mutationFn: () => TicketInstance.ticketsTicketIdFinishAutoPut(params)
        }
    },
    // 상세 작업 완료
    finishProcess: (params: TicketApiTicketsTicketIdFinishProcessPutRequest) => {
        return {
            mutationKey: [params.ticketId],
            mutationFn: () => TicketInstance.ticketsTicketIdFinishProcessPut(params)
        }
    },
    // 강제출고
    forceDeliver: (params: TicketApiTicketsTicketIdForceDeliverPutRequest) => {
        return {
            mutationKey: [params.ticketId, params.ticketsTicketIdForceDeliverPutRequest],
            mutationFn: () => TicketInstance.ticketsTicketIdForceDeliverPut(params)
        }
    },
    // 접수건 강제 정비 종료
    forceFinish: (params: TicketApiTicketsTicketIdForceFinishPutRequest) => {
        return {
            mutationKey: [params.ticketId, params.ticketsTicketIdForceFinishPutRequest],
            mutationFn: () => TicketInstance.ticketsTicketIdForceFinishPut(params)
        }
    },
    // 주차위치 업데이트 내역 조회
    parkingLocationHistory: (params: TicketApiTicketsTicketIdParkingLocationHistoryGetRequest) => {
        return {
            mutationKey: [params.ticketId],
            mutationFn: () => TicketInstance.ticketsTicketIdParkingLocationHistoryGet(params)
        }
    },
    // 지연 사유 발생
    pendAuto: (params: TicketApiTicketsTicketIdPendAutoPutRequest) => {
        return {
            mutationKey: [params.ticketId, params.ticketsTicketIdPendAutoPutRequest],
            mutationFn: () => TicketInstance.ticketsTicketIdPendAutoPut(params)
        }
    },
    // 지연 해소
    resumeAuto: (params: TicketApiTicketsTicketIdResumeAutoPutRequest) => {
        return {
            mutationKey: [params.ticketId, params.ticketsTicketIdResumeAutoPutRequest],
            mutationFn: () => TicketInstance.ticketsTicketIdResumeAutoPut(params)
        }
    },
    // 첫번째 작업 단계 시작 또는 작업단계 시작
    startAuto: (params: TicketApiTicketsTicketIdStartAutoPutRequest) => {
        return {
            mutationKey: [params.ticketId],
            mutationFn: () => TicketInstance.ticketsTicketIdStartAutoPut(params)
        }
    },
    // 상세 작업 시작
    startProcess: (params: TicketApiTicketsTicketIdStartProcessPutRequest) => {
        return {
            mutationKey: [params.ticketId, params.ticketsTicketIdStartProcessPutRequest],
            mutationFn: () => TicketInstance.ticketsTicketIdStartProcessPut(params)
        }
    },
    // 주차위치 업데이트
    updateParkingLocation: (params: TicketApiTicketsTicketIdUpdateParkingLocationPutRequest) => {
        return {
            mutationKey: [params.ticketId, params.ticketsTicketIdUpdateParkingLocationPutRequest],
            mutationFn: () => TicketInstance.ticketsTicketIdUpdateParkingLocationPut(params)
        }
    },
    // 접수대기를 접수건으로
    awaitingReceipt: (params: TicketApiTicketsTicketIdAwaitingReceiptPutRequest) => {
        return {
            mutationKey: [params.ticketId],
            mutationFn: () => TicketInstance.ticketsTicketIdAwaitingReceiptPut(params)
        }
    },
    // 임시정비 시작
    temporaryStageInProgress: (params: TicketApiTicketsTicketIdAwaitingStagePutRequest) => {
        return {
            mutationKey: [params.ticketId],
            mutationFn: () => TicketInstance.ticketsTicketIdAwaitingStagePut(params)
        }
    }
});