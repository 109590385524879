import { Button, Flex, Select, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiProcessesProcessIdPutRequest, ProcessesGet200ResponseRowsInner } from "@sizlcorp/mbk-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mutateProcess } from "api/processType/useProcessQuery";
import { LabeledInput } from "components/Input/Atom";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import LabeledTextarea from "components/Textarea/Atom/LabeledTextarea";

const ProcessForm = Object.assign({}, {
    Title: Title,
    Input: LabeledInput,
    TextArea: LabeledTextarea,
    Select: Select,
});

interface Props {
    formatterProps: ProcessesGet200ResponseRowsInner;
}
export const ProcessTypeUpdateForm = ({ formatterProps }: Props) => {

    const form = useForm({
        initialValues: {
            siteId: formatterProps.siteId,
            name: formatterProps.name,
        },
    });

    const { closeModal } = useModal();
    const queryClient = useQueryClient();
    
    const { mutate : updateMutate } = useMutation(
        (params : MasterApiProcessesProcessIdPutRequest) => 
            mutateProcess.update(params).mutationFn(params as MasterApiProcessesProcessIdPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["processes"]);
            }
        }
    );

    const onSubmit = async () => {

        if (!form.values.name) {
            alert("상세정비 명을 입력해주세요.")
            return;
        }

        try {
            await updateMutate({
                processId: formatterProps.id,
                processesGetRequest: {
                    siteId: formatterProps.siteId!,
                    name: form.values.name!,
                }
            }, {
                onSuccess: () => {
                    alert("상세정비가 수정되었습니다.");
                    closeModal({});
                }
            });
        } catch (error) {
            alert("Error! please try again");
            console.log("Ticket Create Error ::: ", error);
        }
    };

    return (
        <Flex direction="column" gap="md" w="15rem">
            <TextInput
                withAsterisk
                label="상세정비 명"
                placeholder="상세정비 명을 입력해주세요."
                {...form.getInputProps("name")}
            />
            <Flex justify="flex-end" gap="md">
                <Button
                    onClick={closeModal}
                    color="gray"
                >
                    취소
                </Button>
                <Button onClick={onSubmit}>
                    상세정비 수정
                </Button>
            </Flex>
        </Flex>
    )
};
