import { useCallback, useState } from 'react';

// useLoading 커스텀 훅: 비동기 작업의 로딩 상태 관리
export const useLoading = (): [boolean, (asyncFunction: () => Promise<any>) => Promise<void>] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // 비동기 작업을 실행하고 로딩 상태를 관리하는 함수
    const executeWithLoading = useCallback(async (asyncFunction: () => Promise<any>): Promise<void> => {
        setIsLoading(true); // 작업 시작 시 로딩 상태로 설정
        try {
            await asyncFunction(); // 비동기 작업 실행
        } finally {
            setIsLoading(false); // 작업 완료 후 로딩 해제
        }
    }, []);

    return [isLoading, executeWithLoading];
};

// • 타입 안정성: isLoading은 항상 boolean 타입이고, executeWithLoading 함수는 항상 비동기 함수를 받습니다.
// • 비동기 작업에 대한 타입 지정: executeWithLoading 함수는 비동기 작업이므로, 항상 Promise<void>를 반환하여 타입 안정성을 보장합니다.