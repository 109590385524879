import { Button, Flex, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiPendingReasonsPostRequest, PendingReasonsGetRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mutatePendingReasons } from "api/pending/usePendingReasonsQuery";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";

const ReasonRegisterForm = () => {
    const formData = useForm<PendingReasonsGetRequest>({
        initialValues: {
            siteId: Number(localStorage.getItem("siteId")),
            name: "",
            pendingType: "ACCEPT_PENDING",
        }
    });

    const { closeModal } = useModal();

    const queryClient = useQueryClient();

    const { mutate : mutatePendingReasonsPost } = useMutation(
        (params : MasterApiPendingReasonsPostRequest) =>
            mutatePendingReasons.create(params).mutationFn(params as MasterApiPendingReasonsPostRequest | any), 
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["pendingReasons"]);
            }
        }
    );
    
    const onSubmit = async () => {

        if (!(formData.values.name.length > 0)) {
            alert("필수 항목들을 입력해주세요.")
            return;
        }

        try {
            mutatePendingReasonsPost({
                pendingReasonsGetRequest: {
                    siteId: Number(localStorage.getItem("siteId")),
                    name: formData.values.name,
                    pendingType: formData.values.pendingType,
                }
            }, {
                onSuccess: () => {
                    alert("지연사유가 추가되었습니다.");
                    closeModal({});
                }
            })
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Flex direction="column" gap="md" w="15rem">
            <TextInput
                withAsterisk
                label="지연사유 명"
                placeholder="지연사유 명을 입력해주세요."
                {...formData.getInputProps("name")}
            />
            <Select
                withAsterisk
                label="지연종류"
                data={[
                    { value: "ACCEPT_PENDING", label: "접수 완료 지연" },
                    { value: "PENDING", label: "정비 중 지연" },
                    { value: "DELIVERY_PENDING", label: "출고 지연" },
                ]}
                withinPortal
                {...formData.getInputProps("pendingType")}
            />
            <Flex justify="flex-end" gap="md">
                <Button color="gray" onClick={closeModal}>
                    취소
                </Button>
                <Button onClick={onSubmit}>
                    지연사유 추가
                </Button>
            </Flex>
        </Flex>
    )
};

export default ReasonRegisterForm;
