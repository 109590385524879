import { Button, Flex, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiProcessesPostRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mutateProcess } from "api/processType/useProcessQuery";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";

export const ProcessTypeCreateForm = () => {

    const form = useForm({
        initialValues: {
            siteId: localStorage.getItem("siteId"),
            name: "",
        },
    });

    const { closeModal } = useModal();

    const queryClient = useQueryClient();
    
    const { mutate : createMutate } = useMutation(
        (params : MasterApiProcessesPostRequest) => 
            mutateProcess.create(params).mutationFn(params as MasterApiProcessesPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["processes"]);
            }
        }
    );

    const onSubmit = async () => {
        if (!form.values.name) {
            alert("상세정비 명을 입력해주세요.")
            return;
        }

        try {
            const siteId = localStorage.getItem("siteId");
            if (siteId) {
                await createMutate({
                    processesGetRequest: {
                        siteId: Number(siteId),
                        name: form.values.name!,
                    }
                }, {
                    onSuccess: () => {
                        alert("상세정비가 추가되었습니다.");
                        closeModal({});
                    },
                })
            }
        } catch (error) {
            alert("Error! please try again");
            console.log("Ticket Create Error ::: ", error);
        }
    };

    return (
        <Flex direction="column" gap="md" w="15rem">
            <TextInput
                withAsterisk
                label="상세정비 명"
                placeholder="상세정비 명을 입력해주세요."
                {...form.getInputProps("name")}
            />
            <Flex justify="flex-end" gap="md">
                <Button
                    onClick={closeModal}
                    color="gray"
                >
                    취소
                </Button>
                <Button onClick={onSubmit}>
                    상세정비 추가
                </Button>
            </Flex>
        </Flex>
    )
};

