import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiProcessesFindPostRequest, MasterApiProcessesGetRequest, MasterApiProcessesPostRequest, MasterApiProcessesProcessIdDeleteRequest, MasterApiProcessesProcessIdGetRequest, MasterApiProcessesProcessIdPutRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { MasterInstance } from "instance/axios";


export const process = createQueryKeys("process", {
    all : null,
    get : (params : MasterApiProcessesGetRequest) => {
        const query = { ...params };

        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => MasterInstance.processesGet(query)
        }
    },
    find : (params : MasterApiProcessesFindPostRequest) => {
        const query = { ...params };

        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => MasterInstance.processesFindPost(query)
        }
    },
    detail : (params : MasterApiProcessesProcessIdGetRequest) => {
        return {
            queryKey : [params.processId, params],
            queryFn : () => MasterInstance.processesProcessIdGet(params)
        }
    }
})

export const mutateProcess = createMutationKeys("process", {
    create : (params : MasterApiProcessesPostRequest) => ({
        mutationKey : [params.processesGetRequest],
        mutationFn : () => MasterInstance.processesPost(params)
    }),
    update : (params : MasterApiProcessesProcessIdPutRequest) => ({
        mutationKey : [params.processId, params.processesGetRequest],
        mutationFn : () => MasterInstance.processesProcessIdPut(params)
    }),
    delete : (params : MasterApiProcessesProcessIdDeleteRequest) => ({
        mutationKey : [params.processId],
        mutationFn : () => MasterInstance.processesProcessIdDelete(params)
    })
});