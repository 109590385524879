import { MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ModalStackManager from "components/Modal/Atom/context/ModalStackManager";
import ThemeProvider from "provider/ThemeProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import GlobalStyle from "style/Global";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider>
        <MantineProvider withGlobalStyles>
          <QueryClientProvider client={queryClient}>
            <ModalStackManager>
              <GlobalStyle />
              <App />
            </ModalStackManager>
          </QueryClientProvider>
        </MantineProvider>
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>
);
