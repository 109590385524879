export const receptionSearchType = [
    { value: "", label: "전체" },
    { value: "customerName", label: "고객명" },
    { value: "plateCode", label: "차량번호" },
    { value: "carModel", label: "차량모델" },
];

export const usersSearchType = [
    { value: "", label: "전체" },
    { value: "email", label: "아이디" },
    { value: "role", label: "포지션" },
]

export const pendingReasonsSearchType = [
    { value: "", label: "전체" },
    { value: "name", label: "지연사유명" },
    { value: "pendingType", label: "지연종류" },
]

export const processType = [
    { value: "", label: "전체" },
    { value: "name", label: "상세정비명" },
];