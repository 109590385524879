import { Flex, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface ContentProps {
    showOnlyMine: boolean
    name: string;
    value: number;
}

export const Content = (params: ContentProps) => {
    const { name, value, showOnlyMine } = params;
    const navigate = useNavigate();

    const today = dayjs().format("YYYY-MM-DD");

    const handleClick = () => {
        navigate("/reception", { state: { status: 'DONE', date: today, showOnlyMine } });
    }

    const highlightColorCondition = name === "정비 완료" && value > 0

    return (
        <>
            <Flex
                direction="column"
                w="100%"
                p="2rem"
                c={highlightColorCondition ? "blue.6" : "black.6"}
                style={{
                    border: "1px solid black", borderRadius: "1rem",
                    cursor: highlightColorCondition ? "pointer" : "default"
                }}
                onClick={highlightColorCondition ? handleClick : undefined}
            >
                <Text fz="lg">{name}</Text>
                <Text
                    fw="bold"
                    fz="36px"
                    ta="center"
                    underline={highlightColorCondition}
                >
                    {value} 건</Text>
            </Flex>
        </>
    )
}