import styled from "@emotion/styled";

interface ContainerProps {
    children?: React.ReactNode;
}

export const Container = ({ children }: ContainerProps) => {
    return (
        <ContainerBox>{children}</ContainerBox>
    )
}

const ContainerBox = styled.div`
    width : 100%;
    height : 100%;
    display: flex;
    flex-direction: column;
`