import { ReceptionProvider } from "pages/receptions/Reception/context/ReceptionProvider";
import { createContext } from "react";
import ReceptionArchivePage from "./Reception";

export const ReceptionsCTX = createContext<any>(null);

function Index() {
    const contextValue = { test: "true" };

    return (
        <ReceptionsCTX.Provider value={contextValue}>
            <ReceptionProvider>
                <ReceptionArchivePage />
            </ReceptionProvider>
        </ReceptionsCTX.Provider>
    );
}

export default Index;
