import { Anchor, Title } from "@mantine/core";

interface FileDropZoneTitleProps {
  rightSection?: React.ReactNode;
  leftSection?: React.ReactNode;
  children: React.ReactNode;
}

export const FileDropZoneTitle = ({
  children,
  leftSection,
  rightSection,
}: FileDropZoneTitleProps) => {
  return (
    <Title>
      <Anchor inline>
        {leftSection && <div>{leftSection}</div>}
        {children}
        {rightSection && <div>{rightSection}</div>}
      </Anchor>
    </Title>
  );
};