
import { createContext } from "react";

import { useQuery } from "@tanstack/react-query";
import { users } from "api/users/useUsersQuery";
import BaseBox from "components/Box/BaseBox";
import TestBaseButton from "components/Button/Atom/TestBaseButton";
import BaseContents from "components/Contents/BaseContents";
import InformationManagement from "./Information";
export const InformationCTX = createContext<any>(null);


const Information = Object.assign({}, '', {
  Content: BaseContents,
  Box: BaseBox,
  Button: TestBaseButton,
});

function InformationPage() {

  const { data } = useQuery(users.who({}));

  return (
    <Information.Content title="내 정보 관리">
      <Information.Box>
        <InformationManagement userData={data?.data} />
      </Information.Box>
    </Information.Content>
  );
}

export default InformationPage;
