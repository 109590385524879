import styled from '@emotion/styled';
import { icons } from 'modules/icons';

// 스타일 컴포넌트 정의
const BoxContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  padding: 1.875rem 2.5rem;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 20px #d7d8dd;
`;

const BoxTitle = styled.h3`
  position: relative;
  margin-bottom: 30px;
  font-size: 20px;

`;

interface BoxProps {
    title?: string
    children: React.ReactNode
    isEtc?: boolean
}

// 함수형 컴포넌트 정의
const BaseBox = (params: BoxProps) => {
    const { title, children, isEtc } = params
    return (
        <BoxContainer className="box">
            {
                title && <BoxTitle>
                    {title}
                    {
                        isEtc &&
                        <icons.DotIcon />
                    }
                </BoxTitle>
            }
            {children}
        </BoxContainer>
    );
};

export default BaseBox;
