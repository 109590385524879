import dayjs from "dayjs";
import { createContext, useMemo, useState } from "react";

export interface ReceptionContextProps {
    Date?: [Date | null, Date | null]; // string;
}

const today = dayjs().startOf('day').toDate();
const oneWeekAgo = dayjs().subtract(7, 'day').startOf('day').toDate();

export const ReceptionStateContext = createContext({
    Date: [oneWeekAgo, today],
});

export const ReceptionActionsContext = createContext({});

export const ReceptionProvider = ({ children }: any) => {
    const [ReceptionState, setReceptionState]: any = useState<ReceptionContextProps>({
        Date: [oneWeekAgo, today],
    });

    const actions: any = useMemo(
        () => ({
            setDate: (Date: Date[]) => {
                setReceptionState((prev: any) => ({
                    ...prev,
                    Date,
                }));
            },
        }),
        []
    );
    
    return (
        <ReceptionActionsContext.Provider value={actions}>
            <ReceptionStateContext.Provider value={ReceptionState}>
                {children}
            </ReceptionStateContext.Provider>
        </ReceptionActionsContext.Provider>
    );
}