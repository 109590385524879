import { Button, Flex, Select, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { TicketsGet200ResponseRowsInner, TicketsGet200ResponseRowsInnerShippingInfoTypeEnum } from "@sizlcorp/mbk-api-document/dist/models";

import useTicketMutation from "api/tickets/useTicketStatusChange";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";

export const deliveryTypeList = [
  {
    value: TicketsGet200ResponseRowsInnerShippingInfoTypeEnum.SELF,
    label: "직접출고",
  },
  {
    value: TicketsGet200ResponseRowsInnerShippingInfoTypeEnum.PROVIDER,
    label: "탁송",
  },
  {
    value: TicketsGet200ResponseRowsInnerShippingInfoTypeEnum.RENTAL,
    label: "렌탈",
  }
];

const ShippingInfoModal = ({
  customerInfo,
  formatterProps,
}: {
  customerInfo?: { contact: string }
  formatterProps: TicketsGet200ResponseRowsInner;
}) => {

  const formData = useForm({
    initialValues: {
      // TODO: shippingInfo에 타입 맞추어 ENUM 적용.
      shippingInfoType: TicketsGet200ResponseRowsInnerShippingInfoTypeEnum.SELF, // formatterProps?.row?.shippingInfo?.type,
      shippingInfoContact: customerInfo?.contact ?? "",
      shippingInfoAddress: "",
      shippingInfoDescription: "",
      shippingInfoTime: ""
    }
  })

  const { closeModal } = useModal();

  const { deliverMutate, forceDeliverMutate } = useTicketMutation();

  const onSubmit = async () => {
    try {
      deliverMutate({
        ticketId: formatterProps?.id,
        ticketsTicketIdDeliverPutRequest: {
          shippingInfo: {
            type: formData.values.shippingInfoType as TicketsGet200ResponseRowsInnerShippingInfoTypeEnum,
            contact: formData.values.shippingInfoContact ?? undefined,
            address: formData.values.shippingInfoAddress ?? undefined,
            description: formData.values.shippingInfoDescription ?? undefined,
            shippingInfoTime: new Date().toISOString()
          }
        }
      }, {
        onSuccess: () => {
          alert("출고 처리 완료되었습니다.");
          closeModal({});
        }
      })
    } catch (error) {
      alert("Error! please try again");
    }
  };

  const onForceDeliver = async () => {
    try {
      forceDeliverMutate({
        ticketId: formatterProps?.id,
        ticketsTicketIdForceDeliverPutRequest: {
          shippingInfo: {
            type: formData.values.shippingInfoType as TicketsGet200ResponseRowsInnerShippingInfoTypeEnum,
            contact: formData.values.shippingInfoContact ?? undefined,
            address: formData.values.shippingInfoAddress ?? undefined,
            description: formData.values.shippingInfoDescription ?? undefined,
            shippingInfoTime: new Date().toISOString()
          }
        }
      }, {
        onSuccess: () => {
          alert("강제 출고 처리 완료되었습니다.");
          closeModal({});
        }
      });
    } catch (error) {
      alert("Error! please try again");
    }
  }

  const shippingInfoTypeValidate = formData.values.shippingInfoType;

  return (
    <Flex direction="column" w="30rem" gap="md">
      <Select
        withAsterisk
        label="출고방법"
        data={deliveryTypeList}
        {...formData.getInputProps("shippingInfoType")}
      />
      <TextInput
        {...formData.getInputProps("shippingInfoContact")}
        label="연락처"
        placeholder="연락처를 입력해 주세요"
      />
      <TextInput
        {...formData.getInputProps("shippingInfoAddress")}
        label="주소"
        placeholder="주소를 입력해 주세요"
      />
      <Textarea
        {...formData.getInputProps("shippingInfoDescription")}
        label="비고"
        placeholder="비고를 입력해 주세요"
      />
      <Flex justify={'space-between'}>
        <Button
          disabled={!shippingInfoTypeValidate}
          color="red"
          onClick={onForceDeliver}>강제 출고
        </Button>
        <Flex gap={10}>
          <Button
            color="gray"
            onClick={closeModal}
          >
            취소
          </Button>
          <Button
            disabled={!shippingInfoTypeValidate}
            onClick={onSubmit}
            type="submit">
            출고 처리
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
};

export default ShippingInfoModal;
