import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiPendingReasonsFindPostRequest, MasterApiPendingReasonsGetRequest, MasterApiPendingReasonsPendingReasonIdDeleteRequest, MasterApiPendingReasonsPendingReasonIdGetRequest, MasterApiPendingReasonsPendingReasonIdPutRequest, MasterApiPendingReasonsPostRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { MasterInstance } from "instance/axios";


export const pendingReasons = createQueryKeys("pendingReasons", {
    all : null,
    get : (params : MasterApiPendingReasonsGetRequest) => {
        const query = { ...params };

        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => MasterInstance.pendingReasonsGet(query)
        }
    },
    find : (params : MasterApiPendingReasonsFindPostRequest) => {
        const query = { ...params };

        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => MasterInstance.pendingReasonsFindPost(query)
        }
    },
    detail : (params : MasterApiPendingReasonsPendingReasonIdGetRequest) => {
        return {
            queryKey : [params.pendingReasonId, params],
            queryFn : () => MasterInstance.pendingReasonsPendingReasonIdGet(params)
        }
    }
});

export const mutatePendingReasons = createMutationKeys("pendingReasons", {
    create : (params : MasterApiPendingReasonsPostRequest) => ({
        mutationKey : [params.pendingReasonsGetRequest],
        mutationFn : () => MasterInstance.pendingReasonsPost(params)
    }),
    update : (params : MasterApiPendingReasonsPendingReasonIdPutRequest) => ({
        mutationKey : [params.pendingReasonId, params.pendingReasonsGetRequest],
        mutationFn : () => MasterInstance.pendingReasonsPendingReasonIdPut(params)
    }),
    delete : (params : MasterApiPendingReasonsPendingReasonIdDeleteRequest) => ({
        mutationKey : [params.pendingReasonId],
        mutationFn : () => MasterInstance.pendingReasonsPendingReasonIdDelete(params)
    })
});