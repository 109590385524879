import styled from "@emotion/styled";
import { DatePicker, DateValue, DatesRangeValue } from "@mantine/dates";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import 'dayjs/locale/ko';
import { useState } from "react";

export type CalendarDate = [Date | null, Date | null];

export interface CalendarProps {
    dates: CalendarDate;
    type?: 'range' | 'default';
    onChange?: (dates: CalendarDate) => void; // 현재 'range' 타입에서만 사용, 날짜 범위 선택 시 호출. 부모 컴포넌트의 onChange를 수행
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'; // 기본적으로는 'xl'로 설정. 
}

export const isCalendarDate = (value: any): value is CalendarDate => {
    // 첫째, value가 배열인지 확인
    if (!Array.isArray(value) || value.length !== 2) {
        return false;
    }
    // 둘째, 배열의 각 요소가 Date 인스턴스이거나 null인지 확인
    const checkDateOrNull = (item: any) => item === null || item instanceof Date;
    return checkDateOrNull(value[0]) && checkDateOrNull(value[1]);
}

export const Calendar = ({ dates, type = 'default', onChange, size }: CalendarProps) => {
    const { closeModal } = useModal();
    // 단일 날짜 선택 혹은 날짜 범위 선택에 따른 초기 값 설정
    const [value, setValue] = useState<DatesRangeValue | DateValue>(type === 'range' ? dates : dates[0]);

    const handleChange = (selectedDates: DatesRangeValue | DateValue) => {
        setValue(selectedDates);
        // 'range' 타입: 날짜 범위 선택 로직 / 'default' 타입: 단일 날짜 선택 로직
        if (type === 'range' && Array.isArray(selectedDates) && selectedDates[1] !== null) {
            onChange ? onChange(selectedDates) : closeModal(selectedDates);
        } else if (type === 'default' && !Array.isArray(selectedDates) && selectedDates !== null) {
            closeModal([selectedDates, selectedDates]);
        }
    }

    return <MyDatePicker
        type={type === 'range' ? 'range' : 'default'}
        allowSingleDateInRange={type === 'range'}
        firstDayOfWeek={0}
        value={value}
        onChange={handleChange}
        size={size ?? 'xl'}
        previousIcon={<IconChevronLeft />}
        nextIcon={<IconChevronRight />}
        locale="ko"
        monthLabelFormat="MM/YYYY"
    />
}

const MyDatePicker = styled(DatePicker)`
    .mantine-DatePicker-monthCell:last-child > button[data-weekend="true"] {
        color: blue;
    }
`