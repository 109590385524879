import { ActionIcon, Flex } from "@mantine/core";
import { MasterApiProcessesProcessIdDeleteRequest, ProcessesGet200ResponseRowsInner } from "@sizlcorp/mbk-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mutateProcess } from "api/processType/useProcessQuery";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import BaseTable from "components/Table/Atom/BaseTable";
import { Column, SortColumn, SortDirection } from "react-data-grid";
import { ProcessTypeUpdateForm } from "./processTypeUpdateForm";

export interface SortProps {
    columnKey: string;
    direction: SortDirection | undefined
}

interface ProcessTypeTableProps {
    processType: ProcessesGet200ResponseRowsInner[];
    sortColumns: Map<string, SortProps>
    setSortColumns: React.Dispatch<React.SetStateAction<Map<string, SortProps>>>
    selectedRows: ReadonlySet<any>
    setSelectedRows: React.Dispatch<React.SetStateAction<ReadonlySet<any>>>
}
function ProcessTypeTable(params: ProcessTypeTableProps) {

    const { processType, sortColumns, setSortColumns, selectedRows, setSelectedRows } = params;
    const { openModal } = useModal();
    const queryClient = useQueryClient();

    const { mutate: deleteMutate } = useMutation(
        (params: MasterApiProcessesProcessIdDeleteRequest) =>
            mutateProcess.delete(params).mutationFn(params as MasterApiProcessesProcessIdDeleteRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["process"]);
            }
        }
    )

    const columns: readonly Column<ProcessesGet200ResponseRowsInner>[] = [
        {
            key: "processType",
            name: "상세정비명",
            sortable: true,
            resizable: true,
        },
        {
            key: "options",
            name: "옵션",
            width: 120,
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                // delete item after confirm
                const deleteItem = async (processId: number) => {
                    if (window.confirm("상세정비을 삭제하시겠습니까?")) {
                        deleteMutate({
                            processId,
                        });
                    }
                };

                return (
                    <Flex gap={5}>
                        <ActionIcon bg="white" variant="outline" onClick={() => openModal(<ProcessTypeUpdateForm formatterProps={formatterProps.row} />, '', '상세정비 수정', true)}>
                            <IconEdit />
                        </ActionIcon>
                        <ActionIcon bg="white" variant="outline" onClick={() => {
                            deleteItem(formatterProps?.row?.id);
                        }}>
                            <IconTrash />
                        </ActionIcon>
                    </Flex>
                );
            },
        },
    ];




    return (
        <BaseTable
            style={{
                height: '30rem',
            }}
            columns={columns}
            rows={processType?.map((row) => {
                return {
                    ...row,
                    processType: row.name,
                }
            })}
            rowKeyGetter={(row: ProcessesGet200ResponseRowsInner) => row.id}
            sortColumns={Array.from(sortColumns.values()) as SortColumn[]}
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
        />
    )
};

export default ProcessTypeTable;
