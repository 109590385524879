import styled from "@emotion/styled";

interface RowProps {
    children: React.ReactNode;
    borderTop?: string;
}

export const Row = ({ children, borderTop }: RowProps) => {
    return (
        <RowBox
            style={{borderTop : borderTop}}
        >
            {children}
        </RowBox>
    )
}

const RowBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #babeca;
    height: auto;
`