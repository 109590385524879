import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import BaseInstance from "instance/axios";

// 엑셀 양식 다운로드
const fetchInterportPost = (): Promise<AxiosResponse> =>
    BaseInstance.interportExportTicketPost({
        responseType: 'blob'
    })


export const useInterportExcelMutationQuery = () => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('ko-KR');
    return useMutation(
        () => fetchInterportPost().then(response => response),
        {
            onSuccess: (response: AxiosResponse) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `접수현황_${formattedDate}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    );
}
