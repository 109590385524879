import styled from "@emotion/styled";

interface ContentBoxProps {
    children: React.ReactNode;
    height?: string;
}

export const ContentBox = ({ children, height }: ContentBoxProps) => {
    return (
        <ContentBoxContainer
            style={{height : height}}
        >
            {children}
        </ContentBoxContainer>
    )
}

const ContentBoxContainer = styled.div`
    flex : 5;
    height: 6rem;
    display : flex;
    align-items : center;
`