import { useEffect, useState } from "react";
// import { DashboardCTX } from ".";
import styled from "@emotion/styled";
import { Button, Checkbox, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiSitesSiteIdPutRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { mutateSite, site } from "api/site/useSiteQuery";
import { Container } from "components/BaseManagement/Container";
import { ContentBox } from "components/BaseManagement/ContentBox";
import { Row } from "components/BaseManagement/Row";
import { TitleBox } from "components/BaseManagement/TitleBox";
import { clockChecklist, messageChecklist } from "utils/siteTranslation";

const ManageMent = Object.assign({}, "", {
  Container: Container,
  Row: Row,
  TitleBox: TitleBox,
  ContentBox: ContentBox,
});

function SiteConfig() {
  const [siteData, setSiteData] = useState<any>({})
  const queryClient = useQueryClient();
  const { mutate: sitePut } = useMutation(
    (params : MasterApiSitesSiteIdPutRequest) => 
      mutateSite.update(params).mutationFn(params as MasterApiSitesSiteIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["site"]);
      }
    }
  );

  const { data } = useQuery(site.detail({
    siteId: Number(localStorage.getItem("siteId"))
  }));

  const form = useForm({
    initialValues: {
      name: siteData?.name,
      contact: siteData?.contact,
    }
  })

  useEffect(() => {
    setSiteData(data?.data)
  }, [data])

  function handleCheckboxChange(key: string, checked: boolean) {
    setSiteData((prevData: any) => {
      if (key === 'usesReceiptNotification') {
        return {
          ...prevData,
          usesReceiptNotification: checked,
        };
      } else {
        return {
          ...prevData,
          transitionNotificationSetting: {
            ...(prevData.transitionNotificationSetting || {}),
            [key]: checked,
          },
        };
      }
    });
  }

  const onSave = async () => {

    sitePut({
      siteId: siteData?.id,
      sitesGetRequest: {
        name: form.values.name ? form.values.name : siteData?.name,
        contact: form.values.contact ? form.values.contact : siteData?.contact,
        transitionNotificationSetting: siteData?.transitionNotificationSetting,
      }
    }, {
      onSuccess: () => {
        alert("센터정보가 수정되었습니다.")
      }
    })
  };

  return (
    <>
      <ManageMent.Container>
        <ManageMent.Row
          borderTop="1px solid #babeca"
        >
          <ManageMent.TitleBox>
            <Text
              fz="14px"
              fw="800"
            >센터명</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox>
            <TextInput
              ml="15px"
              w="18rem"
              defaultValue={siteData?.name}
              {...form.getInputProps('name')}
            />
          </ManageMent.ContentBox>
        </ManageMent.Row>
        <ManageMent.Row>
          <ManageMent.TitleBox>
            <Text
              fz="14px"
              fw="800"
            >센터 전화번호</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox>
            <TextInput
              ml="15px"
              w="18rem"
              defaultValue={siteData?.contact}
              {...form.getInputProps('contact')}
            />
          </ManageMent.ContentBox>
        </ManageMent.Row>
        {/* <ManageMent.Row>
          <ManageMent.TitleBox
            height="8rem"
          >
            <Text
              fz="14px"
              fw="800"
            >수리 단계 사용</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox
            height="8rem"
          >
            <CheckContent>
              {repairChecklist.list.map((key) => {
                return (
                  <Checkbox
                    mb="10px"
                    label={key}
                  />
                )
              })}
            </CheckContent>
          </ManageMent.ContentBox>
        </ManageMent.Row> */}
        <ManageMent.Row>
          <ManageMent.TitleBox
            height="14rem"
          >
            <Text
              fz="14px"
              fw="800"
            >알림톡 사용</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox
            height="14rem"
          >
            <CheckContent>
              <Checkbox
                checked={siteData?.usesReceiptNotification as boolean}
                onChange={(e) => handleCheckboxChange('usesReceiptNotification', e.target.checked)}
                label={"접수 시"}
              />
              {(siteData?.transitionNotificationSetting ?
                Object.entries(siteData?.transitionNotificationSetting) : [])
                .filter(([key]) => {
                  return key === 'START' // 정비시작
                    || key === 'ACCEPT' // 접수완료
                    || key === 'CONFIRM_DONE'
                    || key === 'FINISH' // 정비완료
                    || key === 'DELIVER' // 출고완료
                })
                .map(([key, value]) => (
                  <Checkbox checked={value as boolean} onChange={(e) => handleCheckboxChange(key, e.target.checked)}
                    label={messageChecklist[key]}
                  />
                ))}
            </CheckContent>
          </ManageMent.ContentBox>
        </ManageMent.Row>
        <ManageMent.Row>
          <ManageMent.TitleBox
            height="8rem"
          >
            <Text
              fz="14px"
              fw="800"
            >자동 접수 및 리마인더 사용</Text>
          </ManageMent.TitleBox>
          <ManageMent.ContentBox
            height="8rem"
          >
            <CheckContent>
              <Checkbox
                checked={siteData?.usesAutoAccept as boolean}
                description={siteData?.autoAcceptThreshold + clockChecklist[siteData?.autoAcceptUnit]}
                label={"자동 접수"}
                disabled
              />
              <Checkbox
                checked={siteData?.usesWaitingReminder as boolean}
                description={siteData?.waitingReminderThreshold + clockChecklist[siteData?.waitingReminderUnit]}
                label={"정비 대기 리마인더"}
                disabled
              />
              <Checkbox
                checked={siteData?.usesDoneReminder as boolean}
                description={siteData?.doneReminderThreshold + clockChecklist[siteData?.doneReminderUnit]}
                label={"정비 완료 리마인더"}
                disabled
              />
              <Checkbox
                checked={siteData?.usesDoneConfirmReminder as boolean}
                description={siteData?.doneConfirmReminderThreshold + clockChecklist[siteData?.doneConfirmReminderUnit]}
                label={"출고 대기 리마인더"}
                disabled
              />
            </CheckContent>
          </ManageMent.ContentBox>
        </ManageMent.Row>
      </ManageMent.Container>
      <ButtonContent>
        <Button size="md" onClick={onSave}>저장</Button>
      </ButtonContent>
    </>
  );
}

const CheckContent = styled.div`
  display : grid;
  grid-template-columns: repeat(4, 1fr);
  gap : 4px;
  width: 100%;
  margin-left : 15px;
`
const ButtonContent = styled.div`
  display : flex;
  justify-content : center;
  margin-top : 2rem;
  height: 4rem;
`

export default SiteConfig;
