import styled from "@emotion/styled";

interface TitleBoxProps {
    children: React.ReactNode;
    height?: string;
}

export const TitleBox = ({ children, height }: TitleBoxProps) => {
    return (
        <TitleBoxContainer
            style={{ height: height }}
        >
            {children}
        </TitleBoxContainer>
    )
}

const TitleBoxContainer = styled.div`
    flex : 1;
    height: 6rem;
    background-color : #f6f7fa;
    display : flex;
    align-items : center;
    justify-content: center;
`;