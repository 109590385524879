import styled from "@emotion/styled";

interface FileBoxProps {
  children: React.ReactNode;
}

export const FileBox = ({ children }: FileBoxProps) => {
  return <FileBoxWrapper>{children}</FileBoxWrapper>;
};

const FileBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  padding: 1rem 0.5rem;
  border: 1px solid #bdbbbb;
`;
