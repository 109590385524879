import styled from "@emotion/styled";
import { Button, Flex, Text, Title } from "@mantine/core";
import BaseBox from "components/Box/BaseBox";
import BaseContents from "components/Contents/BaseContents";
import { UrgencyStatusTable } from "pages/dashboard/Dashboards/urgencyStatusTable";
import { useState } from "react";
import MonthChart from "./Dashboards/monthChart";
import { ReceiptNumber } from "./Dashboards/receiptNumber";
import WeekendChart from "./Dashboards/weekendChart";


const DashboardWrapper = styled.div`
  width: 100%;
  display:flex; 
  flex-wrap:wrap; 
  gap: 1rem;

  & > div {
    width: 100%;
  }
  
  @media (min-width:992px){
    .col-4{flex:0 0 33.333333%; max-width:33.333333%; position:relative; width:100%; padding-right:15px; padding-left:15px;};
    .col-8{flex:0 0 66.666667%; max-width:66.666667%; position:relative; width:100%; padding-right:15px; padding-left:15px;};
    .col-12{flex:0 0 100%; max-width:100%; position:relative; width:100%; padding-right:15px; padding-left:15px;};
  }
`

const Dashboard = Object.assign({}, '', {
  Content: BaseContents,
  Wrapper: DashboardWrapper,
  Box: BaseBox
});

function DashboardPage() {

  const [showOnlyMine, setShowOnlyMine] = useState<boolean>(false);

  return (
    <Dashboard.Content title={
      <Flex justify="space-between">
        <Flex gap={10}>
          <Text>정비장 전체현황</Text>
          <Text color="gray">
            (기준일: 오늘)
          </Text>
        </Flex>
        <Flex gap={10}>
          <Button
            variant={showOnlyMine ? 'outline' : "filled"}
            onClick={() => setShowOnlyMine(false)}>
            {"전체"}
          </Button>
          <Button
            variant={showOnlyMine ? 'filled' : "outline"}
            onClick={() => setShowOnlyMine(true)}>
            {"MY"}
          </Button>
        </Flex>
      </Flex>
    }>
      <Dashboard.Wrapper>
        <Flex gap="md" w="100%">
          <Flex bg="white" p="1rem" w="50%" direction="column" gap="1rem" style={{ borderRadius: "0.5rem", boxShadow: "0 0 1.5rem #d7d8dd" }}>
            <Title order={3}>정비단계별 현황</Title>
            <ReceiptNumber showOnlyMine={showOnlyMine} />
          </Flex>
          {/* <Dashboard.Box title="정비단계별 현황" >
            <ReceiptNumber showOnlyMine={showOnlyMine} />
          </Dashboard.Box> */}
          <Flex bg="white" p="1rem" w="50%" direction="column" gap="1rem" style={{ borderRadius: "0.5rem", boxShadow: "0 0 1.5rem #d7d8dd" }}>
            <Title order={3}>신속 조치 필요 항목</Title>
            <UrgencyStatusTable showOnlyMine={showOnlyMine} />
          </Flex>
          {/* <Dashboard.Box title="신속 조치 필요 항목" >
            <UrgencyStatusTable showOnlyMine={showOnlyMine} />
          </Dashboard.Box> */}
        </Flex>
        <Flex
          gap="md"
          w="100%"
          justify='center'
          align='center'
        >
          <Flex bg="white" p="1rem" w="50%" direction="column" gap="1rem" style={{ borderRadius: "0.5rem", boxShadow: "0 0 1.5rem #d7d8dd" }}>
            <Title order={3}>주간 접수 건수</Title>
            <WeekendChart showOnlyMine={showOnlyMine} />
          </Flex>
          {/* <Dashboard.Box title="주간 접수 건수" >
            <WeekendChart showOnlyMine={showOnlyMine} />
          </Dashboard.Box> */}
          <Flex bg="white" p="1rem" w="50%" direction="column" gap="1rem" style={{ borderRadius: "0.5rem", boxShadow: "0 0 1.5rem #d7d8dd" }}>
            <Title order={3}>월간 접수 건수</Title>
            <MonthChart showOnlyMine={showOnlyMine} />
          </Flex>
          {/* <Dashboard.Box title="월간 접수 건수" >
            <MonthChart showOnlyMine={showOnlyMine} />
          </Dashboard.Box> */}
        </Flex>
      </Dashboard.Wrapper>
    </Dashboard.Content >
  );
}





export default DashboardPage;
