import {
  Configuration,
  DefaultApi,
  MasterApi,
  TicketApi,
  TicketUserViewApi,
  UserApi,
} from "@sizlcorp/mbk-api-document/dist/models";
import axios from "axios";
export const BASE_URL = process.env.REACT_APP_BASE_URL ?? "https://mbk-staging.sizl.co.kr/api"


export const axiosInstance: any = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("authToken"),
  },
  timeout: 1000 * 60 * 60 * 24 * 7,
});

export const axiosInstanceNoToken: any = axios.create({
  baseURL: BASE_URL,
  timeout: 1000 * 60 * 60 * 24 * 7,
})

axiosInstance.interceptors.request.use((config: any) => {
  if (!config.headers) return config;
  const accessToken = localStorage.getItem("authToken");

  if (accessToken && config.headers) {
    config.headers["Authorization"] = "Bearer " + accessToken;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async (error: any) => {
    if (
      error.config &&
      error.response &&
      error.response.status !== 401 &&
      error.response.status !== 400
    ) {
      error.config._retry = true;
      const refreshToken = localStorage.getItem("authToken");
      error.config.headers.refreshToken = refreshToken;

      return axios.create({
        baseURL: BASE_URL,
        headers: {
          Authorization: "Bearer " + refreshToken,
          "Content-Type": "application/json",
          withCredentials: true,
        },
      });
      // .then((res) => {
      //     if (res.status === 200 && res.data.refreshToken) {
      //         localStorage.setItem('authToken', res.data.accessToken)
      //         const accessToken = localStorage.getItem('authToken')
      //         error.config.headers["Authorization"] = accessToken
      //         return axiosInstance(error.config)
      //     }
      // })
    }

    return Promise.reject(error);
  }
);

const baseAxios = (url: string) => {
  return new DefaultApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance
  );
};

const userAxiosNoToken = (url: string) => {
  return new UserApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstanceNoToken
  );
}

const userAxios = (url: string) => {
  return new UserApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance
  );
};

const ticketAxios = (url: string) => {
  return new TicketApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance
  );
}

const masterAxios = (url: string) => {
  return new MasterApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance
  );
};

export const ticketUserAxios = (url?: string) => {
  return new TicketUserViewApi(
    {
      apiKey: localStorage.getItem("authToken"),
    } as Configuration,
    url,
    axiosInstance
  )
}

const BaseInstance = baseAxios(BASE_URL);
export const userInstanceNoToken = userAxiosNoToken(BASE_URL);
export const TicketInstance = ticketAxios(BASE_URL);
export const MasterInstance = masterAxios(BASE_URL);
export const UserInstance = userAxios(BASE_URL);
export const TicketUserViewInstance = ticketUserAxios(BASE_URL);
export default BaseInstance;
