import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { UserResponse } from '@sizlcorp/mbk-api-document/dist/models';
import { AxiosResponse } from 'axios';
import { authEnum } from 'config/auth';
import BaseInstance from 'instance/axios';
import { icons } from 'modules/icons';
import React, { useEffect, useState } from 'react';

// 스타일 컴포넌트에 타입 정의
interface DropdownProps {
  active?: boolean;
}

const NavbarTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 10px 30px;
  background: #fff;
  border-bottom: 1px solid #dfe0e8;
`;

const DropdownMenu = styled.ul<DropdownProps>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: absolute;
  right: 30px;
  top: 60px;
  width: 100%;
  max-width: 180px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);

  @media (max-width: 1199px) {
    width : 120px;
    top : 44px;
    right : 15px;
  }

  & > li {
    padding:5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > li > a {
    display:block; 
  }

  & > li > a:hover {
    background:#f8f9fa; 
  }


`;

const NavBarInfo = styled.ul`
  display:flex; 
  flex-wrap:wrap; 
  align-items:center;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 1199px) {
    position: absolute;
    top : 1rem;
    right : 0.3rem;
  }

  .dropdown {
    @media (max-width: 1199px) {
      & > span {
        display: none;
      }
      
      & > svg {
        fill : white;
      }
    }
  }
`

const NavbarTop: React.FC = () => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [userData, setUserData] = useState<UserResponse>()
  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const whoAmI = () => {
    BaseInstance.whoamiGet({
      headers: {
        Authorization: `Bearer ${localStorage.getItem(authEnum.authToken)}`,
      },
    }).then((res: AxiosResponse<UserResponse, any>) => {
      setUserData(res.data)
    });
  };

  useEffect(() => { whoAmI() }, [])

  const LogoutButton = async () => {
    localStorage.removeItem(authEnum.authToken)
    localStorage.removeItem('siteId')
    localStorage.removeItem('role')
    window.location.href = '/'
  }
  return (
    <NavbarTopContainer className="navbar-top">
      <NavBarInfo className="info">
        <li>
          <icons.UserIcon />
        </li>
        <li>
          <a className="dropdown" href="#" onClick={toggleDropdown}>
            <span>{userData?.name} 님</span>
            <icons.DropDownIcon />
          </a>
          <DropdownMenu className="dropdown-menu" active={dropdownActive}>
            <li>
              <a href="#">
                <Button leftIcon={<icons.LogoutIcon />} variant='subtle' onClick={LogoutButton}>Logout</Button>
              </a>
            </li>
          </DropdownMenu>
        </li>
      </NavBarInfo>
    </NavbarTopContainer>
  );
};

export default NavbarTop;
