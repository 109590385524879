import { useQuery } from '@tanstack/react-query';
import { ticketReports } from 'api/ticketReport/useTicketReportQuery';
import { BarElement, CategoryScale, Chart as ChartJS, ChartOptions, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { convertArrayToNumbers, extractMaxValue } from 'utils/convert';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const WeekendChart = ({ showOnlyMine }: { showOnlyMine: boolean }) => {
  const { data } = useQuery(ticketReports.postWeekStatus({
    assignedTickets: showOnlyMine
  }));

  const dayLabels = data?.data.map((item) => item.day);
  const dayCounts = data?.data.map((item) => item.count) ?? [];
  const dayMaxData = extractMaxValue(convertArrayToNumbers(dayCounts)) * 1.2;

  const chartData = {
    labels: dayLabels,
    datasets: [
      {
        label: '',
        data: dayCounts,
        backgroundColor: '#008DFC',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        // color: (context) => {
        //   const value = context.dataset.data[context.dataIndex];
        //   return value === 0 ? 'black' : 'white';
        // },
        font: {
          weight: 'bold',
          size: 16,
        },
        formatter: (value) => {
          return value;
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        beginAtZero: true,
        max: dayMaxData, // y축 최대값 설정
      },
    },
  } as ChartOptions<"bar">;

  return (
    <Bar data={chartData} options={options} style={{ position: "relative" }} height={100} />
  );
};

export default WeekendChart;
