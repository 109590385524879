import { createContext } from "react";

import ReceptionsComplete from "./Reception";
import { ReceptionProvider } from "./Reception/context/ReceptionProvider";


export const ReceptionsCTX = createContext<any>(null);

function Index() {
  const contextValue = { test: "true" };

  return (
    <>
      <ReceptionsCTX.Provider value={contextValue}>
        <ReceptionProvider>
          <ReceptionsComplete />
        </ReceptionProvider>
      </ReceptionsCTX.Provider>
    </>
  );
}

export default Index;
