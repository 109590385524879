
import { Box, Button, FileButton, Flex, Group, Text } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { useInterportExcelMutationQuery } from "api/interport/useInterportMutate";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import BaseInstance from "instance/axios";
import { useRef, useState } from 'react';
import { useInterportMutationQuery } from "../api/useInterportQuery";

export const FileImportForm = () => {
    const [file, setFile] = useState<File | undefined>(undefined);
    const { mutate: interportMutation } = useInterportMutationQuery();
    const { mutate: excelUpload } = useInterportExcelMutationQuery();
    const queryClient = useQueryClient();
    const { closeModal } = useModal();

    const resetRef = useRef<() => void>(null);

    const clearFile = () => {
        setFile(undefined);
        resetRef.current?.();
    };

    // 파일 업로드 처리
    const handleFileUpload = async (file: File | undefined) => {

        await BaseInstance.interportImportPost({
            file: file
        })
            .then((res) => {
                if (res.status === 200) {
                    alert("엑셀 파일이 성공적으로 업로드 되었습니다.");
                    queryClient.invalidateQueries(['tickets']);
                    closeModal({});
                } else {
                    alert("데이터가 맞지 않아 업로드에 실패했습니다.");
                }
            }).catch((err) => {
            });
    };

    return (
        <Box maw={520} mx="auto" w={400} h="auto">
            <Group position="center">
                <FileButton
                    key={Math.random()} // key 추가
                    onChange={(file) => setFile(file || undefined)}
                    accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                >
                    {(props) => <Button color="indigo" w="100%" {...props}>파일 선택</Button>}
                </FileButton>
            </Group>
            {file && (
                <Text size="sm" align="center" mt="sm">
                    업로드 파일: {file.name}
                </Text>
            )}
            <Flex justify="space-between" align="center" gap="sm" mt="lg">
                <Button
                    w={'8rem'}
                    bg={'red'}
                    styles={{
                        root: {
                            ":hover": {
                                backgroundColor: 'coral'
                            }
                        }
                    }}
                    onClick={(e) => {
                        excelUpload()
                    }}
                >
                    {'업로드 양식 받기'}
                </Button>
                <Flex gap="xs">
                    <Button disabled={!file} color="teal" onClick={clearFile}>
                        Reset
                    </Button>
                    <Button onClick={() => handleFileUpload(file)}>저장</Button>
                </Flex>
            </Flex>

        </Box>
    )
};