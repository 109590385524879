import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import type { DataGridProps } from "react-data-grid";
import DataGrid from "react-data-grid";
interface Props extends Partial<DataGridProps<any>> {
  additionalCSS?: SerializedStyles;
}

export default function BaseTable({
  additionalCSS,
  rows,
  columns,
  ...rest
}: Props) {
  return (
    <GridWrapper>
      <DataGrid
        className="fill-grid rdg-light"
        rows={rows ?? []}
        columns={columns ?? []}
        headerRowHeight={44}
        rowHeight={80}
        {...rest}
      />
    </GridWrapper>
  );
}

const GridWrapper = styled.div`
  .fill-grid {
    border: none;
    & *[role="columnheader"] {
      color: #000000;
      border: none;
      box-shadow: none;
      display: flex;
      align-items: center;
    }

    & *[role="row"]:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  
    & *[role="gridcell"] {
      display: flex;
      align-items: center;
      border-left: none;
      border-right: none;
      box-shadow: none;
      color: #000000;
      & > * {
        justify-content: flex-start;
      }
    }
  }
`;
