import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { axiosInstance } from "instance/axios";
import printJS from "print-js";



const fetchPrintPost = (params: { ticketId: string[] }) =>
    axiosInstance.post("/ticket/issue",
        { ticketId: params.ticketId },
        { responseType: "arraybuffer" }
    )

export const usePrintPost = () => {
    return useMutation(
        (params: { ticketId: string[] }) => fetchPrintPost({ ticketId: params.ticketId }),
        {
            onSuccess: (response: AxiosResponse) => {
                const pdfFile = new Blob([response.data], {
                    type: "application/pdf",
                });
                const pdfUrl = URL.createObjectURL(pdfFile);
                printJS(pdfUrl);
            }
        }
    );
};

export default usePrintPost;
