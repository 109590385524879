// dayjsConfig.js
import dayjs from 'dayjs';
import 'dayjs/locale/ko'; // 한국어 로케일 불러오기
import localizedFormat from 'dayjs/plugin/localizedFormat';

// dayjs 설정 함수
const setupDayjs = () => {
    dayjs.extend(localizedFormat);  // 플러그인 적용
    dayjs.locale('ko');  // 로케일 설정
};

export default setupDayjs;