import styled from "@emotion/styled";

import { useEffect, useState } from "react";
import { IoDownloadOutline } from "react-icons/io5";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

export function FilePreviewForm({ file, onClose, fileName }: any) {
  const [numPages, setNumPages] = useState<number>(0);
  const [scale, setScale] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const onDownLoad = () => {
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(file);
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 575) {
        setScale(0.7); // 작은 화면에 대한 스케일
      } else {
        setScale(0.89); // 큰 화면에 대한 스케일
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // 초기 스케일 설정

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        style={{
          background: "#FFFFFF",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "2px 7px",
        }}
      >
        <IoDownloadOutline
          onClick={onDownLoad}
          size={"2.2rem"}
          style={{ cursor: "pointer" }}
        />
      </div>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderTextLayer={false}
            scale={scale}
          />
        ))}
      </Document>
    </>
  );
}

const PreviewWrapper = styled.div`
  width: 60rem;
  height: 60rem;
  overflow: scroll;
  position: relative;
  @media (max-width: 575px) {
    width: 70rem;
    height: 100rem;
  }
`;
