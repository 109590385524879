import styled from '@emotion/styled';
import React from 'react';

// 스타일 컴포넌트 정의
const ContentsContainer = styled.div`
  padding: 50px 30px;
`;

const Heading = styled.h2`
  position: relative;
  margin-bottom: 30px;
  font-size: 25px;
`;


interface BaseContentsProps {
    children: React.ReactNode;
    title: any
}

// 함수형 컴포넌트 정의
const BaseContents = (params: BaseContentsProps) => {

    const { children, title } = params;
    return (
        <ContentsContainer className="contents">
            <Heading>
                {title}
            </Heading>
            {children}
        </ContentsContainer>
    );
};

export default BaseContents;
