import { Badge, Button, Table, Text } from "@mantine/core";
import { IconCircleArrowUp } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { ticketReports } from "api/ticketReport/useTicketReportQuery";
import { stepStatusDisplay } from "constants/receptionStatusList";
import { useNavigate } from "react-router-dom";



export const UrgencyStatusTable = ({ showOnlyMine }: { showOnlyMine: boolean }) => {

    const headers = ['시그널', '진행상태', '차량번호', '차량모델', '고객명', '바로가기'];

    const navigate = useNavigate();
    const { data } = useQuery(ticketReports.postUrgencyStatus({
        assignedTickets: showOnlyMine
    }));

    const urgencyStatusList = data?.data;

    const ths = (
        <tr>
            {headers.map((header, index) => (
                <th
                    key={index}
                    style={{
                        textAlign: 'center',
                    }}
                >
                    {header}
                </th>
            ))}
        </tr>
    );

    const rows = urgencyStatusList?.map((element) => (
        <tr key={element.id}>
            <td align="center">
                <Badge
                    size="sm"
                    style={{
                        border: 'none',
                        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)', // 그림자 추가
                    }}
                    bg={`${element?.ticketHistories?.[0].signal}.6`} />
            </td>
            <td><Text ta="center" fz="sm">{stepStatusDisplay(element.stepStatus)}</Text></td>
            <td><Text ta="center" fz="sm">{element.plateCode}</Text></td>
            <td><Text ta="center" fz="sm">{element.carModel}</Text></td>
            <td><Text ta="center" fz="sm">{element.customerName}</Text></td>
            {/* <td>{element.createUser?.name}</td> */}
            <td style={{ textAlign: "center" }}>
                <Button compact size="xs" onClick={() => {
                    navigate("/reception", {
                        state: {
                            ticketId: element.id,
                            showOnlyMine,
                            date: element.incomingAt,
                        }
                    });
                }}>
                    <IconCircleArrowUp stroke={2} size="1.2rem" />
                </Button>
            </td>
        </tr>
    ));

    return (
        <Table fontSize={'md'} >
            <thead>{ths}</thead>
            <tbody>{rows}</tbody>
        </Table>
    );
}