/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.07.30 (화)
 * 작성내용 : 입력 배열에서 undefined 값을 0으로 변환하고, 모든 요소를 숫자로 변환한다.
 *           배열 요소는 string 또는 undefined일 수 있다.
 * @param data (string | undefined)[] - 변환할 문자열 또는 undefined가 포함된 배열
 * @returns number[] - 변환된 숫자 배열
 */
export const convertArrayToNumbers = (data: (number | string | undefined)[]): number[] => {
    return data.map(item => item === undefined ? 0 : Number(item));
}

/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.07.30 (화)
 * 작성내용 : 주어진 숫자 배열에서 최대값을 추출한다.
 * @param numbers number[] - 최대값을 찾을 숫자 배열
 * @returns number - 배열에서의 최대값
 */
export const extractMaxValue = (numbers: number[]): number => {
    return Math.max(...numbers);
}

/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.09.30 (월)
 * 작성내용 : 주어진 초(seconds)를 일, 시간, 분, 초 단위로 변환하여 "0일 0시간 0분 0초" 형식의 문자열로 반환하는 함수입니다.
 * 
 * @param {number} seconds - 변환할 총 초(second) 단위의 시간
 * @returns {string} - "0일 0시간 0분 0초" 형식으로 변환된 시간 문자열
 * 
 * @description 
 * 이 함수는 초 단위의 시간을 입력받아 일, 시간, 분, 초 단위로 변환하고 이를 포맷화하여 문자열로 반환합니다.
 * 일(day) 단위는 주어진 초를 24시간으로 나눈 몫으로 계산하고, 남은 초를 이용해 시간(hour), 분(minute), 초(second)를 각각 나머지 연산을 통해 구합니다.
 * 
 * 예시:
 *  - 입력값이 3233355초일 경우, 출력은 "37일 10시간 29분 15초"입니다.
 */
export const convertFormatDuration = (seconds: number): string => {
    // 1일은 24시간, 1시간은 3600초(60*60), 1분은 60초로 계산
    const days = Math.floor(seconds / (24 * 60 * 60)); // 총 초를 일 단위로 변환
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60)); // 남은 초에서 시간 단위 계산
    const minutes = Math.floor((seconds % (60 * 60)) / 60); // 남은 초에서 분 단위 계산
    const remainingSeconds = seconds % 60; // 나머지 초 계산

    // 포맷화된 결과를 반환
    return `${days}일 ${hours}시간 ${minutes}분 ${remainingSeconds}초`;
}