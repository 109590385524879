import { createMutationKeys, createQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiUsersGetRequest, MasterApiUsersPostRequest, MasterApiUsersUserIdDeleteRequest, MasterApiUsersUserIdGetRequest, MasterApiUsersUserIdPutRequest, UserApiAuthSignupPostRequest } from "@sizlcorp/mbk-api-document/dist/models";
import BaseInstance, { MasterInstance, UserInstance } from "instance/axios";

export const users = createQueryKeys("users", {
    all : null,
    get : (params : MasterApiUsersGetRequest) => {
        const query = { ...params };

        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => MasterInstance.usersGet(query)
        }
    },
    detail : (params : MasterApiUsersUserIdGetRequest) => {
        return {
            queryKey : [params.userId, params],
            queryFn : () => MasterInstance.usersUserIdGet(params)
        }
    },
    who : (params) => {
        const token = localStorage.getItem('authToken');
        return {
            queryKey : [params],
            queryFn : () => BaseInstance.whoamiGet({ headers: { Authorization: `Bearer ${token}` }} )
        }
    }
});

export const mutateUser = createMutationKeys("users", {
    create : (params : MasterApiUsersPostRequest) => ({
        mutationKey : [params.authSignupPostRequest],
        mutationFn : () => MasterInstance.usersPost(params)
    }),
    update : (params : MasterApiUsersUserIdPutRequest) => ({
        mutationKey : [params.userId, params.authSignupPostRequest],
        mutationFn : () => MasterInstance.usersUserIdPut(params)
    }),
    delete : (params : MasterApiUsersUserIdDeleteRequest) => ({
        mutationKey : [params.userId],
        mutationFn : () => MasterInstance.usersUserIdDelete(params)
    }),
    signup : (params : UserApiAuthSignupPostRequest) => ({
        mutationKey : [params.authSignupPostRequest],
        mutationFn : () => UserInstance.authSignupPost(params, { headers: { Authorization: undefined } })
    }),
});