/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.01.05 (금)
 * 작성내용 : 키보드 이벤트가 발생했을 때, Enter 키를 눌렀는지 확인한다.
 *          Enter 키를 눌렀다면 callback 함수를 실행한다.
 * @param e 키보드 이벤트
 * @param callback Enter 키를 눌렀을 때 실행할 함수
 */
export const handleKeyDown = (e: React.KeyboardEvent, callback: () => void) => {
  e.stopPropagation();
  if (e.key === "Enter") {
    callback();
  }
};
