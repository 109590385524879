export const messageChecklist: { [key: string]: string } = {
    START: "정비 시작",
    ACCEPT: "접수 완료",
    FINISH: "정비 완료",
    DELIVER: "출고완료",
    PEND_WAIT: "정비단계 시작 전 지연",
    PEND_STAGE: "정비단계 중 지연",
    PEND_ACCEPT: "접수완료 지연",
    RESUME_WAIT: "정비 전 지연 해소",
    START_STAGE: "정비 단계 시작시",
    CONFIRM_DONE: "정비 완료", //"정비 종료 확인",
    FINISH_STAGE: "정비 단계 완료",
    FORCE_FINISH: "강제 종료",
    PEND_PROCESS: "상세정비 지연",
    RESUME_STAGE: "정비중 지연 해소",
    CANCEL_FINISH: "종료 취소",
    FORCE_DELIVER: "강제 출고",
    PEND_DELIVERY: "출고 지연",
    RESUME_ACCEPT: "정비 대기 지연 해소",
    START_PROCESS: "상세정비 시작",
    FINISH_PROCESS: "상세 정비 종료",
    RESUME_PROCESS: "상세 정비중 지연 해소",
    PEND_STAGE_WAIT: "정비 단계 대기 지연",
    RESUME_DELIVERY: "출고 지연 해소",
    RESUME_STAGE_WAIT: "정비 대기 단계 지연 해소",
    CANCEL_FINISH_TO_WAITING: "정비 종료 취소",
    CANCEL_FINISH_TO_STAGE_WAITING: "정비 종료 취소"
};

export const clockChecklist: { [key: string]: string } = {
    seconds: "초",
    minutes: "분",
    hours: "시간",
}