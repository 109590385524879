import { Flex } from "@mantine/core"
import { TicketsTodayStatusPost200ResponseInner } from "@sizlcorp/mbk-api-document/dist/models"
import { useQuery } from "@tanstack/react-query"
import { ticketReports } from "api/ticketReport/useTicketReportQuery"
import { Content } from "./receiptNumber/content"


const Receipt = Object.assign({}, {
    Content: Content
})

export const ReceiptNumber = ({ showOnlyMine }: { showOnlyMine: boolean }) => {

    const { data } = useQuery(ticketReports.postTodayStatus({
        assignedTickets: showOnlyMine
    }));

    const todayTicketLog = data?.data;

    return (
        <Flex justify="flex-start" gap="md">
            {
                todayTicketLog?.map((item: TicketsTodayStatusPost200ResponseInner) => {
                    return (
                        <Receipt.Content
                            showOnlyMine={showOnlyMine}
                            key={item.status}
                            name={item.status ?? ""}
                            value={item.count ?? 0}
                        />
                    )
                })
            }
        </Flex>
    )
}