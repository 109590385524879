import BaseBox from "components/Box/BaseBox";
import BaseContents from "components/Contents/BaseContents";
import { createContext } from "react";
import SiteConfig from "./Siteonfig/SiteConfig";

const Site = Object.assign({}, '', {
  Content : BaseContents,
  Box : BaseBox,
});

type ProjectsProps = {
  title?: string,
}
export const ProjectsCTX = createContext<any>(null);
function SiteConfigPage({ title } : ProjectsProps) {
  const contextValue = { test: "true" };

  return (
    <>
      <Site.Content title="센터관리">
        <Site.Box>
          <SiteConfig />
        </Site.Box>
      </Site.Content>
    </>
  );
}

export default SiteConfigPage;
