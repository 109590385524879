import { Button, Checkbox, Flex, TextInput, Textarea } from "@mantine/core";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";

import { useForm } from "@mantine/form";
import { TicketApiTicketsTicketIdPutRequest, TicketResponse, TicketsGetRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { mutateTickets } from "api/tickets/useTicketsQuery";
import { users } from "api/users/useUsersQuery";
import { FormatterProps } from "react-data-grid";

// 차량번호 유효성 검사 패턴
// const pattern = /^[0-9]{2,3}[가-힣]{1}[0-9]{4}$/;

interface EditFormProps {
  formatterProps: FormatterProps<TicketResponse, unknown>;
}

const EditForm = ({ formatterProps }: EditFormProps) => {
  const formData = useForm<TicketsGetRequest>({
    initialValues: {
      plateCode: formatterProps.row.plateCode,
      customerName: formatterProps.row.customerName,
      mobile: formatterProps.row.mobile,
      siteId: Number(localStorage.getItem("siteId")),
      // vinCode, context, carModel, usesNotification은 mutate할 때 검증해서 undefined로 보낼 것. 기본값은 ""으로 한다.
      vinCode: formatterProps.row.vinCode ?? "",
      context: formatterProps.row.context ?? "",
      carModel: formatterProps.row.carModel ?? "",
      usesNotification: formatterProps.row.usesNotification,
      parkingLocation: formatterProps?.row.parkingLocation ?? "",
    }
  });
  const { data } = useQuery(users.who({}));
  const loginUserId = data?.data.id;

  const { closeModal } = useModal();

  const queryClient = useQueryClient();
  const { mutate: mutateTicketsPut } = useMutation(
    (params: TicketApiTicketsTicketIdPutRequest) =>
      mutateTickets.update(params).mutationFn(params as TicketApiTicketsTicketIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["tickets"]);
      }
    }
  );

  const onSubmit = async () => {
    const plateCode = formData.values.plateCode;

    if (!(plateCode.length > 0 && formData.values.customerName.length > 0 && formData.values.mobile.length > 0)) {
      alert("필수 항목들을 입력해주세요.");
      return;
    }

    // if (!pattern.test(plateCode)) {
    //   alert("차량 번호 형식이 올바르지 않습니다. 숫자 2~3자리, 한글 1자리, 숫자 4자리 형식으로 입력해주세요.");
    //   return;
    // }

    try {
      mutateTicketsPut({
        ticketId: formatterProps.row.id,
        ticketsTicketIdDeleteRequest: {
          plateCode: plateCode,
          customerName: formData.values.customerName,
          mobile: formData.values.mobile,
          siteId: Number(localStorage.getItem("siteId")),
          vinCode: formData.values.vinCode || undefined,
          context: formData.values.context || undefined,
          carModel: formData.values.carModel || undefined,
          usesNotification: formData.values.usesNotification ?? false,
          parkingLocation: formData.values.parkingLocation || undefined,
          assignedAdviserId: loginUserId
        }
      }, {
        onSuccess: () => {
          alert("접수가 수정되었습니다.");
          closeModal({});
        }
      })
    } catch (error) {
      alert("Error! please try again");
      console.log("Ticket Update Error ::: ", error);
    }
  }

  return (
    <Flex w="30rem" direction="column" gap="1rem">
      <TextInput
        withAsterisk
        autoFocus
        label="차량번호"
        maxLength={8}
        placeholder="예: 00가0000"
        {...formData.getInputProps("plateCode")}
      />
      <TextInput
        label="모델"
        placeholder="예: E200"
        {...formData.getInputProps("carModel")}
      />
      <TextInput
        label="VIN"
        placeholder="예: XXXXX41JXMN109XXX"
        {...formData.getInputProps("vinCode")}
      />
      <Flex w="100%" gap="md">
        <TextInput
          withAsterisk
          w="50%"
          label="고객명"
          placeholder="예: 홍길동"
          {...formData.getInputProps("customerName")}
        />
        <TextInput
          withAsterisk
          w="50%"
          label="전화번호"
          placeholder="예: 010-1234-5678"
          {...formData.getInputProps("mobile")}
        />
      </Flex>
      <TextInput
        label="주차 위치"
        placeholder="주차 위치 입력"
        {...formData.getInputProps("parkingLocation")}
      />
      <Textarea
        label="비고"
        placeholder="필요한 추가 정보 입력"
        {...formData.getInputProps("context")}
      />
      <Flex justify="space-between" align="center">
        <Checkbox
          defaultChecked={formData.values.usesNotification}
          label="알림톡 사용"
          {...formData.getInputProps("usesNotification")}
        />
        <Flex gap="md">
          <Button color="gray" onClick={closeModal}>
            취소
          </Button>
          <Button onClick={onSubmit}>
            접수 수정
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
};

export default EditForm;


