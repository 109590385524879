export const StepStatus = {

    "TEMPORARY_RECEIPTED": "접수대기",
    "RECEIPTED": "접수완료",
    "ACCEPT_PENDING": "접수 완료 지연중",
    "WAITING": "정비 대기",
    "PENDING": "정비 전 지연",
    "TEMPORARY_STAGE_IN_PROGRESS": "임시 정비단계 진행중",
    "STAGE_IN_PROGRESS": "정비단계 진행중",
    "IN_PROGRESS_PENDING": "정비단계 진행중 지연",
    "STAGE_WAITING": "정비단계 대기",
    "WAITING_PENDING": "정비단계 시작 전 지연",
    "PROCESSING": "상세 정비",
    "PROCESS_PENDING": "상세 정비 지연",
    "DONE": "정비 완료",

    "DONE_CONFIRMED": "정비 완료 확인",
    "DELIVERY_PENDING": "출고 지연",
    "CLOSED": "정비 마감",
    "DELIVERED": "출고 완료",
};

