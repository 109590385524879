import { TicketsGet200ResponseRowsInnerStepStatusEnum } from "@sizlcorp/mbk-api-document/dist/models";

export function stepStatusDisplay(stepStatus?: TicketsGet200ResponseRowsInnerStepStatusEnum): string {

    switch (stepStatus) {
        case TicketsGet200ResponseRowsInnerStepStatusEnum.RECEIPTED:
            return "접수완료";
        case TicketsGet200ResponseRowsInnerStepStatusEnum.ACCEPT_PENDING:
            return "접수완료 지연"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.TEMPORARY_RECEIPTED:
            return "접수대기"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.WAITING:
            return "정비대기"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.TEMPORARY_STAGE_IN_PROGRESS:
            return "임시 정비단계 진행중"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.PENDING:
            return "정비지연"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.STAGE_IN_PROGRESS:
            return "정비단계 진행중"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.IN_PROGRESS_PENDING:
            return "정비단계 진행중 지연"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.PROCESSING:
            return "상세 정비중"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.PROCESS_PENDING:
            return "상세 정비 지연"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.STAGE_WAITING:
            return "정비단계 대기"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.WAITING_PENDING:
            return "정비단계 대기 지연"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.DONE:
            return "정비완료"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.DONE_CONFIRMED:
            return "정비완료 확인"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.DELIVERY_PENDING:
            return "출고지연"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.DELIVERED:
            return "출고완료"
        case TicketsGet200ResponseRowsInnerStepStatusEnum.CLOSED:
            return '정비마감'
        default:
            return "알수없음"
    }
}


export const receptionStatusList = {
    "ALL": 'ALL',
    "TEMPORARY_RECEIPTED": [TicketsGet200ResponseRowsInnerStepStatusEnum.TEMPORARY_RECEIPTED],
    "WAITING": [TicketsGet200ResponseRowsInnerStepStatusEnum.WAITING, TicketsGet200ResponseRowsInnerStepStatusEnum.STAGE_WAITING],
    "PROGRESS": [TicketsGet200ResponseRowsInnerStepStatusEnum.STAGE_IN_PROGRESS],
    "PENDINGALL": [
        TicketsGet200ResponseRowsInnerStepStatusEnum.PENDING,
        TicketsGet200ResponseRowsInnerStepStatusEnum.ACCEPT_PENDING,
        TicketsGet200ResponseRowsInnerStepStatusEnum.IN_PROGRESS_PENDING,
        TicketsGet200ResponseRowsInnerStepStatusEnum.PROCESS_PENDING,
        TicketsGet200ResponseRowsInnerStepStatusEnum.WAITING_PENDING,
        TicketsGet200ResponseRowsInnerStepStatusEnum.DELIVERY_PENDING,
    ],
    "DONE": [
        TicketsGet200ResponseRowsInnerStepStatusEnum.DONE,
        TicketsGet200ResponseRowsInnerStepStatusEnum.DONE_CONFIRMED,
    ],
    "DELIVERED": [TicketsGet200ResponseRowsInnerStepStatusEnum.DELIVERED],
    "CLOSED": [TicketsGet200ResponseRowsInnerStepStatusEnum.CLOSED],
    "ACCEPT_PENDING": [TicketsGet200ResponseRowsInnerStepStatusEnum.ACCEPT_PENDING],
    "PENDING": [TicketsGet200ResponseRowsInnerStepStatusEnum.PENDING],
    "DELIVERY_PENDING": [TicketsGet200ResponseRowsInnerStepStatusEnum.DELIVERY_PENDING],
};


export const receptionStatusKeys = Object.keys(receptionStatusList) as Array<keyof typeof receptionStatusList>;

export function convertFilterToKorean(key: keyof typeof receptionStatusList): string {
    switch (key) {
        case "ALL":
            return "전체";
        case "TEMPORARY_RECEIPTED":
            return "접수대기";
        case "WAITING":
            return "대기";
        case "PROGRESS":
            return "진행 중";
        case "PENDINGALL":
            return "정비 지연";
        case "DONE":
            return "완료";
        case "DELIVERED":
            return "출고 완료";
        case "CLOSED":
            return "정비 마감";
        case "ACCEPT_PENDING":
            return "접수완료 지연";
        case "PENDING":
            return "정비중 지연";
        case "DELIVERY_PENDING":
            return "출고지연";
        default:
            return "";
    }
}
