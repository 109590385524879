import { DefaultApiInterportImportPostRequest } from "@sizlcorp/mbk-api-document/dist/models";
import { UseMutationResult, useMutation } from "@tanstack/react-query";
import BaseInstance from "instance/axios";

export const useInterportMutationQuery = (): UseMutationResult<void, unknown, DefaultApiInterportImportPostRequest, unknown> => {
    const interportMutation = useMutation(
        async (file: DefaultApiInterportImportPostRequest) => {
            await BaseInstance.interportImportPost(file, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        },
    );
    return interportMutation;
};
