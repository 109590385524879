import { Button, Flex, Pagination, Select } from "@mantine/core";
import { ProcessesGet200ResponseRowsInner } from "@sizlcorp/mbk-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { process } from "api/processType/useProcessQuery";
import BaseBox from "components/Box/BaseBox";
import BaseContents from "components/Contents/BaseContents";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import SearchBox, { SearchProps } from "components/SearchBox";
import { pageOptions } from "constants/pageOptions";
import { processType } from "constants/searchType";
import { SortProps } from "pages/receptions/Reception/ReceptionTable";
import { useState } from "react";
import { ProcessTypeCreateForm } from "./processTypeCreateForm";
import ProcessTypeTable from "./processTypeTable";



const filteredSearchType = processType.filter(type => type.value).map(type => type.value);

const ProcessType = Object.assign({}, '', {
    Content: BaseContents,
    Box: BaseBox,
    SearchBox: SearchBox,
});

function ProcessTypePage() {

    const { openModal } = useModal();
    const [selectedRows, setSelectedRows] = useState((): ReadonlySet<any> => new Set());
    const [perPageRows, setPerPageRows] = useState(5); // code changes for ticket #564 by TBI
    const [sortColumns, setSortColumns] = useState<Map<string, SortProps>>(
        (new Map() as Map<string, SortProps>).set('createdAt', {
            columnKey: 'createdAt',
            direction: undefined
        })
    )

    const [search, setSearch] = useState<SearchProps>({
        search: "",
        searchFields: [],
        pageSize: "10",
    });

    const [page, setPage] = useState<number>(1)

    const { data } = useQuery(process.get({
        page: page,
        pageSize: perPageRows,
        search: search.search,
        searchFields: search.searchFields.length ? search.searchFields : filteredSearchType,
        sort: "-createdAt",
    }));

    return (
        <ProcessType.Content title={
            <Flex justify={'space-between'}>
                {'상세정비 관리'}
                <Button
                    bg={'blue'}
                    onClick={() => openModal(<ProcessTypeCreateForm />, '', '상세정비 추가', true)}
                >
                    {'상세정비 추가'}
                </Button>
            </Flex>
        }>
            <ProcessType.Box>
                <Flex justify={'space-between'} mb={20}>
                    <Select
                        placeholder="페이지당 개수"
                        data={pageOptions}
                        onChange={(e) => {
                            setPage(1)
                            setPerPageRows(Number(e))
                        }}
                    >
                    </Select>
                    <SearchBox searchType={processType} setSearch={setSearch} />
                </Flex>
                <ProcessTypeTable
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    sortColumns={sortColumns}
                    setSortColumns={setSortColumns}
                    processType={data?.data?.rows as ProcessesGet200ResponseRowsInner[]}
                />
                <Flex justify={'center'} mt={20}>
                    <Pagination
                        value={page}
                        total={data?.data?.totalPages as number}
                        onChange={(value) => setPage(value)}
                    />
                </Flex>
            </ProcessType.Box>
        </ProcessType.Content >
    )

}



export default ProcessTypePage;
